var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.second),expression:"second"}],staticClass:"design-container",attrs:{"element-loading-text":`生产图制作中${_vm.second}秒`,"element-loading-background":"rgba(255,255,255,1)"}},[(_vm.statusMsg)?_c('div',{attrs:{"id":"status"}},[_vm._v(_vm._s(_vm.statusMsg))]):_vm._e(),_c('el-main',{staticClass:"design-content"},[(_vm.workspaceWidth > 0)?_c('div',{staticClass:"content--center",style:({
        width: _vm.workspaceWidth + 'px',
        height: _vm.workspaceHeight + 'px',
      }),attrs:{"id":"workspace"}},[_c('div',{staticClass:"editor-canvas"},[_c('div',{staticClass:"editor-canvas-bg",style:({
            width: _vm.canvasW * _vm.zoomScale + 'px',
            height: _vm.canvasH * _vm.zoomScale + 'px',
          })}),_c('fabric-editor',{ref:"editor",attrs:{"index":0,"value":_vm.designData,"width":_vm.canvasW,"height":_vm.canvasH,"left":_vm.designFaceShiftX,"top":_vm.designFaceShiftY},on:{"zoomChange":_vm.handleZoomChange,"confirm":function($event){return _vm.setCanvas(0)}}})],1)]):_vm._e(),_c('div',{staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"999"},attrs:{"id":"svgContent"}}),_c('canvas',{staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"999"},attrs:{"id":"hecheng"}}),_c('canvas',{ref:"barcode",staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"999"},attrs:{"id":"barcode"}}),_c('canvas',{ref:"qrcodeCanvas",staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"999"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }